import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const isInWindow = () => {
  const inSSR = typeof window === "undefined";
  return !inSSR;
};

export const OnlyRunInWindow = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const _isInWindow = isInWindow();

  if (_isInWindow) {
    return children;
  } else {
    return null;
  }
};

export const RecaptchaProvider = ({ children }: { children: any }) => {
  const _isInWindow = isInWindow();

  if (!_isInWindow) {
    return children;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};
