// @ts-ignore
import { Flex, Text } from "rebass";
import React from "react";

export const FormLabel = ({
  children,
  required,
}: {
  children: React.ReactNode;
  required?: boolean;
}) => {
  return (
    <Flex mb={1}>
      <Text mr={2} color="#2C4050" fontWeight="700">
        {children}
      </Text>
      {required && (
        <Text fontWeight="700" color="red">
          *
        </Text>
      )}
    </Flex>
  );
};

export default FormLabel;
