import React from "react";
import { Helmet } from "react-helmet";
import { Heading, Image, Box } from "rebass";
import SignupIllustration from "../../assets/images/signup-illustration.svg";
import Page from "../../components/Page";
import IndexLayout from "../../layouts";
import WidthMaxer from "../../components/WidthMaxer";
import HeroGrid from "../../components/HeroGrid";
import HeroGridCard from "../../components/HeroGridCard";
import HeroLayout from "../../components/HeroLayout";
import PageSection from "../../components/PageSection";
import SignUpForm from "../../components/signup/SignUpForm";
import { RecaptchaProvider } from "../../components/signup/utils";
import * as styles from "./Signup.module.css";

const SignUpPage = () => {
  // useEffect(() => {
  //   if (process.env.NODE_ENV !== "development") {
  //     window.location.pathname = "/contact-us";
  //   }
  // });

  return (
    <>
      <RecaptchaProvider>
        <IndexLayout withBlackLogo={true}>
          <Helmet>
            <title>
              Skodel - Trial Skodel's wellbeing solution for free at your school
            </title>
            <meta
              property="og:title"
              content="Skodel - Trial Skodel's wellbeing solution for free at your school"
            />
            <meta
              property="og:description"
              content="Sign up to Skodel for free to trial our wellbeing solutions at your school. No credit card required."
            />
          </Helmet>
          <Page>
            <HeroGrid
              style={{
                position: "relative",
                overflow: "hidden",
                rowGap: "0",
              }}
            >
              <PageSection className={styles.signup} color="white" id="top">
                <HeroGridCard className={styles.signup__grid}>
                  <WidthMaxer>
                    <HeroLayout
                      className={styles.signup__layout}
                      copy={
                        <Box className={styles.signup__formContainer}>
                          <Heading
                            fontWeight={600}
                            mb={4}
                            style={{
                              fontSize: "42px",
                              lineHeight: "57px",
                              color: "#2C4050",
                            }}
                          >
                            Start your free trial
                          </Heading>
                          <SignUpForm organizationType="SCHOOL" />
                        </Box>
                      }
                      image={
                        <>
                          <Box
                            backgroundColor="#f9f8ff"
                            className={styles.signup__illustrationBg}
                          />
                          <Image
                            className={styles.signup__illustration}
                            src={SignupIllustration}
                            alt=""
                          />
                        </>
                      }
                      imageProps={{
                        className: styles.signup__illustrationContainer,
                      }}
                    />
                  </WidthMaxer>
                </HeroGridCard>
              </PageSection>
            </HeroGrid>
          </Page>
        </IndexLayout>
      </RecaptchaProvider>
    </>
  );
};

export default SignUpPage;

/*
let cbInstance = null;

function initChargebee() {
  if (window.Chargebee && cbInstance === null) {
    cbInstance = window.Chargebee.init({
      site: "skodel-test",
    });
  }
}
*/

/*
e.preventDefault();

if (!isFormValid) {
  return false;
}

initChargebee();

const cart = cbInstance.getCart();
const product = cbInstance.initializeProduct(
  "skodel-premium",
  1
);
cart.replaceProduct(product);

var customer = {
  first_name: firstName,
  last_name: lastName,
  email: email,
  company: school,
  billing_address: {
    first_name: firstName,
    last_name: lastName,
    company: school,
    email: email,
    country: country,
  },
};
cart.setCustomer(customer);

// product.setCustomData({referral: "yes", corporate_agent: "no"});

cbInstance.setPortalCallbacks({
  subscriptionChanged: (data) =>
    console.log("got data", data),
});

cart.proceedToCheckout();

return false;
*/
