import React from "react";
import { FieldErrors } from "react-hook-form";
import { Flex } from "rebass";
import * as styles from "../../pages/signup/Signup.module.css";
import FieldError from "./FieldError";

export const CheckboxInput = ({
  errors = {},
  children,
  name = "",
  checked,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  errors: FieldErrors;
  checked?: boolean;
}) => {
  const hasErrors = !!errors[name];

  return (
    <>
      {hasErrors && <FieldError error={errors[name as string]} />}
      <Flex alignItems="center">
        <Flex
          width="12px"
          height="12px"
          style={{
            position: "relative",
          }}
        >
          <label className={styles.home__heroRadio}>
            <input
              type="checkbox"
              checked={!!(checked || props.value)}
              {...props}
            />
            <span className={styles.home__heroCheckmark}></span>
          </label>
        </Flex>
        {children}
      </Flex>
    </>
  );
};

export default CheckboxInput;
