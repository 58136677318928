import FieldError from "./FieldError";
import React from "react";
// @ts-ignore
import { Box } from "rebass";

export class NumberInput extends React.Component<
  React.InputHTMLAttributes<any> & { errors: any }
> {
  render() {
    const { errors, name, ...field } = this.props;

    const hasErrors = (errors || {}).hasOwnProperty(name as string);

    return (
      <>
        {hasErrors && <FieldError error={errors[name as string]} />}
        <Box
          style={{
            border: "solid 1px #C4C4C4",
            background: "white",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <input
            type="number"
            min="0"
            max="15000"
            style={{
              width: "100%",
              border: "none",
              outline: "none",
              fontSize: "15px",
              background: "transparent",
              padding: "16px 20px",
            }}
            {...field}
          />
        </Box>
      </>
    );
  }
}

export default NumberInput;
