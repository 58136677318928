// @ts-ignore
import { Box } from "rebass";

export const FieldError = ({
  error,
}: {
  error?: {
    message: string;
  };
}) => {
  if (!error) {
    return null;
  }

  return (
    <Box color="#ff7f81" mb={2} fontWeight="bold">
      {error?.message}
    </Box>
  );
};

export default FieldError;
