import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordPolicy, charsets } from "password-sheriff";
import * as Yup from "yup";

const lengthPolicy = new PasswordPolicy({
  length: {
    minLength: 8,
  },
  contains: {
    expressions: [
      charsets.lowerCase,
      charsets.upperCase,
      charsets.numbers,
      charsets.specialCharacters,
    ],
  },
});

export const formSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please supply a password")
    .min(8, "Password length should be at least 8 characters")
    .test(
      "test-password-format",
      "Your password must contain at least 8 characters, a lowercase letter, uppercase letter, a number and a symbol.",
      (value) => lengthPolicy.check(value)
    ),
  address: Yup.object()
    .typeError(
      "Please supply an address. If you cannot find your address, we may not support your country."
    )
    .required(
      "Please supply an address. If you cannot find your address, we may not support your country."
    )
    .shape({
      country: Yup.string(),
    }),
  firstName: Yup.string().required("Please supply a first name"),
  lastName: Yup.string().required("Please supply a last name"),
  email: Yup.string().required("Please supply a email"),
  licenses: Yup.number()
    .required(
      "Please enter the amount of student licenses you would like to setup"
    )
    .min(0)
    .max(
      10000,
      "Please contact us at info@skodel.com for organizations larger than 10000 students"
    ),
  schoolName: Yup.string().required("Please supply a school name"),
  hasAgreedToTerms: Yup.boolean()
    .required("Please agree to the terms to continue")
    .oneOf([true], "Please agree to the terms to continue"),
  passwordConfirm: Yup.string()
    .required(
      "Please supply your password again so that we can confirm it is correct"
    )
    .min(8, "Password length should be at least 8 characters")
    .test(
      "test-password-format",
      "Your password must contain at least 8 characters, a lowercase letter, uppercase letter, a number and a symbol.",
      (value) => lengthPolicy.check(value)
    )
    .oneOf([Yup.ref("password")], "Passwords should match"),
});

export const validationOpt = { resolver: yupResolver(formSchema) };
