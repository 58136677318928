const GATSBY_AUTH_URL = process.env.GATSBY_AUTH_URL as string;
const GATSBY_ONBOARDING_URL = process.env.GATSBY_ONBOARDING_URL as string;

type GraphqlEndpoints = "auth" | "onboarding";
const GraphqlEndpointMap: Record<GraphqlEndpoints, string> = {
  auth: GATSBY_AUTH_URL,
  onboarding: GATSBY_ONBOARDING_URL,
};

export const makeGraphqlRequest = async (
  endpoint: GraphqlEndpoints,
  body: {
    query: string;
    variables?: {};
  }
): Promise<any> => {
  const response = await fetch(GraphqlEndpointMap[endpoint], {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  return response.json();
};

export interface FetchState {
  isError: boolean;
  isLoading: boolean;
  isSuccessful: boolean;
}
