import { navigate } from "gatsby";
import { Text, Flex, Box } from "rebass";
import { get, useForm, Controller, SubmitHandler } from "react-hook-form";
import { SignupFormValues } from "./form/types";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { makeGraphqlRequest } from "../../lib/api";
import { OnboardUserAndOrganization } from "../../lib/mutations";
import * as styles from "../../pages/signup/Signup.module.css";
import * as globalStyles from "../../styles/Global.module.css";
import { defaultValues } from "./form/constants";
import { validationOpt } from "./form/schema";
import { FormLabel } from "./FormLabel";
import { TextInput } from "./TextInput";
import { CheckboxInput } from "./CheckboxInput";
import { AddressInput } from "./AddressInput";
import NumberInput from "./NumberInput";
import FieldError from "./FieldError";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

export type OrganizationType = "SCHOOL" | "COMPANY";

const convertToGraphQLSignUpInput = (
  values: SignupFormValues,
  organizationType: OrganizationType
) => {
  return {
    user: {
      hasAgreedToTerms: values.hasAgreedToTerms,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
    },
    organization: {
      name: values.schoolName,
      country: values.address?.country,
      organizationType,
      address: values.address,
    },
    quantity: values.licenses,
    recaptchaToken: values.recaptchaToken,
  };
};

interface SignUpFormProps {
  organizationType: OrganizationType;
}

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  width: 30px;
  height: 30px;

  & .path {
    stroke: white;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const SignUpForm = ({
  organizationType: organizationType_,
}: SignUpFormProps) => {
  const [organizationType, setorganizationType] =
    useState<OrganizationType>(organizationType_);
  const form = useForm<SignupFormValues>({
    defaultValues,
    ...validationOpt,
  });

  const [formError, setFormError] = useState<string>();

  const captchaRef = useRef<ReCAPTCHA>(null);

  const onSubmit: SubmitHandler<SignupFormValues> = useCallback(
    async (values: SignupFormValues) => {
      setFormError(undefined);

      const data = await makeGraphqlRequest("onboarding", {
        query: OnboardUserAndOrganization,
        variables: {
          input: convertToGraphQLSignUpInput(values, organizationType),
        },
      });

      captchaRef.current?.reset();
      form.setValue("recaptchaToken", "");
      if (!data?.data?.onboardUserAndOrganization?.success) {
        const message =
          data?.data?.onboardUserAndOrganization?.message ||
          "Something went wrong";
        throw new Error(message);
      }
    },
    [organizationType]
  );

  const onSubmitError = (error: any) => {
    setFormError(error.message);
  };

  const {
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    handleSubmit,
    getValues,
    watch,
  } = form;

  const hasAgreedToTerms = watch("hasAgreedToTerms");
  const recaptchaToken = watch("recaptchaToken");
  useEffect(() => {
    if (isSubmitSuccessful) {
      const email = getValues("email");
      if (organizationType === "SCHOOL") {
        navigate("/signup/school/success", { state: { email } });
      } else {
        navigate("/signup/workplace/success", { state: { email } });
      }
    }
  }, [getValues, isSubmitSuccessful, organizationType]);

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(onSubmit)(e).catch(onSubmitError);
      }}
    >
      <Flex
        style={{
          justifyContent: "space-between",
          marginBottom: "24px",
        }}
      >
        <Box flex="1" mr="3">
          <FormLabel required>First Name</FormLabel>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: true }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                autoComplete="given-name"
                required
                type="text"
                placeholder="First Name"
                errors={errors}
                {...field}
              />
            )}
          />
        </Box>
        <Box flex="1">
          <FormLabel required>Last Name</FormLabel>
          <Controller
            control={control}
            name="lastName"
            rules={{ required: true }}
            render={({ field, formState: { errors } }) => (
              <TextInput
                autoComplete="family-name"
                required
                type="text"
                placeholder="Last Name"
                errors={errors}
                {...field}
              />
            )}
          />
        </Box>
      </Flex>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Organization type</FormLabel>
        <Box
          style={{
            border: "solid 1px #C4C4C4",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <select
            style={{
              width: "100%",
              border: "none",
              outline: "none",
              fontSize: "15px",
              background: "transparent",
              padding: "16px 20px",
            }}
            value={organizationType}
            onChange={(e) =>
              setorganizationType(e.target.value as OrganizationType)
            }
          >
            <option value="COMPANY">Company</option>
            <option value="SCHOOL">School</option>
          </select>
        </Box>
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Organization name</FormLabel>
        <Controller
          control={control}
          name="schoolName"
          render={({ field, formState: { errors } }) => (
            <TextInput
              required
              type="text"
              placeholder="Organization name"
              errors={errors}
              {...field}
            />
          )}
        />
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>
          Estimated number of users (You can change it later)
        </FormLabel>
        <Controller
          control={control}
          name="licenses"
          render={({ field, formState: { errors } }) => (
            <NumberInput required errors={errors} {...field} />
          )}
        />
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Address</FormLabel>
        <Controller
          control={control}
          name="address"
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <>
              <FieldError error={get(errors, "address.country")} />
              <AddressInput errors={errors} {...field} />
            </>
          )}
        />
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Email</FormLabel>
        <Controller
          control={control}
          name="email"
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <TextInput
              required
              type="email"
              placeholder="Email"
              errors={errors}
              {...field}
            />
          )}
        />
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Password</FormLabel>
        <Controller
          control={control}
          name="password"
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <TextInput
              required
              type="password"
              placeholder="********"
              errors={errors}
              {...field}
            />
          )}
        />
      </Box>
      <Box style={{ margin: "0 0 20px 0" }}>
        <FormLabel required>Password (Confirm)</FormLabel>
        <Controller
          control={control}
          name="passwordConfirm"
          rules={{ required: true }}
          render={({ field, formState: { errors } }) => (
            <TextInput
              required
              type="password"
              placeholder="********"
              errors={errors}
              {...field}
            />
          )}
        />
      </Box>
      <Flex mb={3} flexDirection="column">
        <Controller
          control={control}
          name="hasAgreedToTerms"
          rules={{ required: true }}
          render={({
            field: { value, ...fieldProps },
            formState: { errors },
          }) => (
            <CheckboxInput checked={value} errors={errors} {...fieldProps}>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#616D77",
                  paddingLeft: "12px",
                }}
              >
                I have read and understand Skodel's
                <a
                  href="https://skodel.com/tou.pdf"
                  style={{
                    fontWeight: 700,
                    paddingLeft: "5px",
                    color: "#616D77",
                    textDecoration: "none",
                  }}
                >
                  Terms of Use.
                </a>
              </Text>
            </CheckboxInput>
          )}
        />
      </Flex>
      {formError && <FieldError error={{ message: formError }} />}
      <Flex my="20px" justifyContent="center">
        {process.env.GATSBY_RECAPTCHA_V2_SITE_KEY && (
          <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY || ""}
            ref={captchaRef}
            onChange={() => {
              const token = captchaRef.current?.getValue();
              form.setValue("recaptchaToken", token || "");
            }}
          />
        )}
      </Flex>

      <Flex
        py={5}
        // className={styles.home__heroButton}
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems="stretch"
        style={{ padding: "0", columnGap: "30px", rowGap: "10px" }}
      >
        <a
          className={`${globalStyles.btn}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
            fontWeight: 700,
            padding: "12px 30px",
            color: "rgb(25, 42, 68)",
            textAlign: "center",
            borderRadius: "4px",
            background:
              "linear-gradient(133.06deg, rgb(226, 232, 251) 12.07%, rgb(177, 193, 252) 94.82%)",
          }}
          href="/contact-us"
          target="_blank"
        >
          Speak to our team
        </a>
        <button
          className={`${styles.signup__submitButton} ${styles.bg_darkblue}`}
          // className={
          //   organizationType == "COMPANY"
          //     ? styles.signup__submitButton + " " + styles.bg_darkblue
          //     : styles.signup__submitButton
          // }
          disabled={!hasAgreedToTerms || !recaptchaToken}
          type="submit"
          style={{ flex: 1, height: "55px" }}
        >
          {isSubmitting ? (
            <StyledSpinner viewBox="0 0 50 50">
              <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="2"
              />
            </StyledSpinner>
          ) : (
            <>Start free trial</>
          )}
        </button>
      </Flex>
      <Flex justifyContent="center" alignItems="center" marginTop="30px">
        <Text
          className={styles.home__haveAccount}
          style={{
            // padding: "30px 0 0 0",
            fontSize: "14px",
            color: "#2C4050",
          }}
        >
          Already have an account?
          <a
            style={{
              color: "rgb(25 42 68)",
              fontWeight: 700,
              padding: "0 0 0 6px",
            }}
            href="https://app.skodel.com"
          >
            Sign In
          </a>
        </Text>
      </Flex>
    </form>
  );
};
export default SignUpForm;
