import Autocomplete from "react-google-autocomplete";
import FieldError from "./FieldError";
import React from "react";
// @ts-ignore
import { Box } from "rebass";
import { OnlyRunInWindow } from "./utils";

export class AddressInput extends React.Component<{
  name: string;
  errors: any;
  onChange: any;
}> {
  render() {
    const { errors, name, onChange } = this.props;

    const hasErrors = (errors || {}).hasOwnProperty(name as string);

    return (
      <>
        {hasErrors && <FieldError error={errors[name as string]} />}
        <Box
          style={{
            border: "solid 1px #C4C4C4",
            background: "white",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          <OnlyRunInWindow>
            <Autocomplete
              apiKey={process.env.GATSBY_GOOGLE_PLACES_API_KEY}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                fontSize: "15px",
                background: "transparent",
                padding: "16px 20px",
              }}
              options={{
                types: ["establishment"],
                fields: ["place_id", "formatted_address", "address_components"],
              }}
              onPlaceSelected={(place) => {
                let components: {
                  country?: string;
                  subpremise?: string;
                  street_number?: string;
                  route?: string;
                  locality?: string;
                  administrative_area_level_1?: string;
                  postal_code?: string;
                } = {};

                place.address_components.forEach(function (comp: any) {
                  comp.types.forEach(function (typeName: any) {
                    components[typeName as keyof typeof components] =
                      typeName === "country" ? comp.short_name : comp.long_name;
                  });
                });

                const address = {
                  line1: `${
                    components.subpremise ? components.subpremise + "/" : ""
                  }${
                    components.street_number
                      ? `${components.street_number} `
                      : ""
                  }${components.route ? `${components.route}` : ""}`,
                  line2: null,
                  line3: null,
                  city: components.locality,
                  state: components.administrative_area_level_1,
                  zip: components.postal_code,
                  country: components.country,
                };

                // @ts-ignore
                onChange(address);
              }}
            />
          </OnlyRunInWindow>
        </Box>
      </>
    );
  }
}
