// extracted by mini-css-extract-plugin
export var bg_darkblue = "Signup-module--bg_darkblue--bBctf";
export var hero__homeImage = "Signup-module--hero__home--image--GEKD1";
export var hero__homeText = "Signup-module--hero__home--text--M+Td+";
export var home__haveAccount = "Signup-module--home__have-account--5sSFn";
export var home__heroButton = "Signup-module--home__hero-button--pbs4+";
export var home__heroCheckmark = "Signup-module--home__hero-checkmark--Os9Ad";
export var home__heroRadio = "Signup-module--home__hero-radio--mPhOL";
export var signup = "Signup-module--signup--RfaGV";
export var signup__formContainer = "Signup-module--signup__form-container--tNel3";
export var signup__grid = "Signup-module--signup__grid--UkhPo";
export var signup__illustration = "Signup-module--signup__illustration--hlAuA";
export var signup__illustrationBg = "Signup-module--signup__illustration-bg--9tZE3";
export var signup__illustrationContainer = "Signup-module--signup__illustration-container--uB89m";
export var signup__layout = "Signup-module--signup__layout--zDjKC";
export var signup__submitButton = "Signup-module--signup__submit-button--xAtMD";