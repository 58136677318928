import { Box, BoxProps, Flex } from "rebass";
import HeroLayoutGrid from "./HeroLayoutGrid";

type HeroLayoutProps = {
  copy: React.ReactNode;
  copyProps?: BoxProps;
  image: React.ReactNode;
  imageOnLeft?: boolean;
  imageProps?: BoxProps;
} & BoxProps;

const HeroLayout = ({
  copy,
  image,
  copyProps,
  imageProps,
  imageOnLeft = false,
  ...otherProps
}: HeroLayoutProps) =>
  !imageOnLeft ? (
    <HeroLayoutGrid {...otherProps}>
      <Box className="copy" {...copyProps}>
        {copy}
      </Box>
      <Flex
        alignItems="center"
        justifyContent="center"
        className="image"
        // pl={imageOnLeft ? 0 : 4}
        // pr={imageOnLeft ? 4 : 0}
        {...imageProps}
      >
        {image}
      </Flex>
    </HeroLayoutGrid>
  ) : (
    <HeroLayoutGrid {...otherProps}>
      <Flex
        alignItems="center"
        justifyContent="center"
        className="image"
        // px={4}
        {...imageProps}
      >
        {image}
      </Flex>
      <Box className="copy" {...copyProps}>
        {copy}
      </Box>
    </HeroLayoutGrid>
  );

export default HeroLayout;
