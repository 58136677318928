import styled from "styled-components";
import { Box } from "rebass";

const HeroLayoutGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 32px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 0;
    > .image {
      /* display: none; */
      order: 1;
    }
    > .copy {
      justify-content: flex-start !important;
      order: 2;
      padding: 0 32px;
    }
  }
`;
export default HeroLayoutGrid;
