export const OnboardUserAndOrganization = `mutation onboardUserAndOrganization($input: OnboardUserAndOrganizationInput!) {
  onboardUserAndOrganization(input: $input) {
    ... on Operation {
      message
      success
      __typename
    }
  }
}`;

export const RequestEmailConfirmation = `mutation RequestEmailConfirmation($email: String!) {
  requestEmailConfirmation (email: $email)
    {
      __typename
      ... on Operation {
        message
        success
      }
    }
}`;
