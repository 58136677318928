export const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  schoolName: "",
  hasAgreedToTerms: false,
  recaptchaToken: null,
  address: null,
  licenses: 100,
};
